/*.App {*/
  /*text-align: center;*/
/*}*/

/*.App-logo {*/
  /*animation: App-logo-spin infinite 20s linear;*/
  /*height: 40vmin;*/
  /*pointer-events: none;*/
/*}*/

/*.App-header {*/
  /*background-color: #282c34;*/
  /*min-height: 100vh;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  /*font-size: calc(10px + 2vmin);*/
  /*color: white;*/
/*}*/
/*.create-form {*/
  /*display: flex;*/
  /*justify-content: space-around;*/
  /*flex-wrap: wrap;*/
/*}*/
/*.create-form-input {*/
  /*width: 48%;*/
/*}*/
/*img {width: 100%}*/
/*.glue-card-wrapper {*/
  /*display: flex;*/
  /*!*justify-content: space-between;*!*/
/*}*/
/*.glue-card {*/
  /*display: flex;*/
  /*!*width: 30%;*!*/
  /*background: azure;*/
  /*margin: 0 20px;*/
  /*box-sizing: border-box;*/
/*}*/
/*.glue-card p{*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*flex-grow: 1;*/
/*}*/
/*.App-link {*/
  /*color: #61dafb;*/
/*}*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body, html {
    margin: 0;
    padding: 0;
    /*font-family: 'Alice', serif;*/
    font-family: 'Raleway', sans-serif;
}

button, a {
    cursor: pointer;
    outline: none;
}
input {
    font-family: Alice;
}
.gold-hover:hover {
    cursor: pointer;
    background: #fcde40;
    transition: 1s;
}



.gradient-wrapper {
    /*background: linear-gradient(213.11deg, #F0D64B 0%, rgba(255, 255, 255, 0) 57.33%);*/
    background: linear-gradient(213.11deg,#f0d64b,hsla(0,0%,100%,0) 45.33%);
    min-height: 100vh;
    padding: 1px;
}


.header {
    /*margin-top: 50px;*/

}
.header-log {
    /*position: relative;*/
    display: flex;
    flex-direction: column;
}
.header-wrapper {
    display: flex;
    /*position: relative;*/
    max-width: 1195px;
    margin: 50px auto 80px;
    padding: 0 33px;
    /*box-sizing: border-box;*/
    align-items: center;
    justify-content: center;
}
.main-container {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 30px;
}

.header-log img{
    width: 24px;
    height: 24px;
    position: fixed;
    top: 55px;
    left: 10%;
}
.fixed-logo{
    position: fixed;
    bottom: 100px;
    transform: rotate(-90deg);
    left: calc(10% - 30px);
}

.header-info {
    min-width: 152px;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    align-items: center;
    font-family: Alice;
    position: relative;
}

.header-info>button {
    background: none;
    /*border-style: none;*/
    border-radius: 50%;
    padding: 0;
    width: 28px;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    height: 28px;
    border: 1px solid #000000;
}
.show-phones {
    position: absolute;
    background: url("../../assets/images/arrow-point-to-right.png") center no-repeat;
    width: 18px;
    height: 20px;
    border-style: none;
    top: 50%;
    transform: translateY(-50%);
    right: -25px;
}
.header-info {
    text-align: center;
}
.header-info-wrapper {
    position: absolute;
    bottom: -108px;
    right: 0;
    display: flex;
    flex-direction: column;
}

.header-info-wrapper>span {
    border-top: 2px solid #F0D64B;
    padding-top: 5px;
}

.naw {
    display: flex;
    min-width: 770px;
    justify-content: space-between;
    margin-left: 220px;
}

.naw a {
    text-decoration: none;
    font-family: Alice;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    transition: 1s;
    padding: 5px 0;
    border-bottom: 2px solid transparent;

}

.naw a:hover {
    border-bottom: 2px solid #F0D64B;
    /*padding-bottom: 3px;*/
}


.banner {}
.banner-title {
    display: flex;
    /*justify-content: center;*/
    /*align-items: center;*/
    width: 690px;
    margin: 0 auto;
    position: relative;
}
.banner-title-wrapper {
    min-width: 560px    ;
}
.banner-title-wrapper p{
    /*padding-left: 73px;*/
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    /*text-align: center;*/
    color: #000000;
    margin: 0;

}
.banner-title img {
    margin-top: 10px;
    width: 130px;
    height: 130px;
}
.banner-title-wrapper h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 96px;
    line-height: 113px;
    color: #212121;
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0 0 0 13px;
    min-width: 547px;
    min-height: 114px;
    box-sizing: border-box;
}

.banner-title-wrapper h1{
    /*position: relative;*/
    /*top: 50%;*/
    /*width: 600px;*/

    /*margin: 0 auto;*/
    /*border-right: 2px solid rgba(255,255,255,.75);*/
    /*font-size: 180%;*/
    /*text-align: center;*/
    /*white-space: nowrap;*/
    /*overflow: hidden;*/
    /*transform: translateY(-50%);*/
}

/* Animation */
/*.banner-title-wrapper h1{*/
    /*animation: typewriter 2s steps(8) 1s 1 normal both,*/
    /*blinkTextCursor 500ms steps(8) infinite normal;*/
/*}*/
/*@keyframes typewriter{*/
    /*from{width: 0;}*/
    /*to{width: 545px;}*/
/*}*/
/*@keyframes blinkTextCursor{*/
    /*from{border-right-color: rgba(255,255,255,.75);}*/
    /*to{border-right-color: transparent;}*/
/*}*/

.banner-subtitle {
    width: fit-content;
    margin: 15px auto 0;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #000000;

}
.banner-photo {
    display: flex;
    width: 100%;
    height: 311px;
    position: relative;
    margin: 30px 0 40px;
}

.banner-photo img:nth-child(3) {
    position: absolute;
    width: 300px;
    height: 311px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.banner-photo img:nth-child(2) {
    position: absolute;
    width: 200px;
    height: 242px;
    left: 50%;
    top: 50%;
    transform: translate(-145%, -50%);
    z-index: 2;
}
.banner-photo img:nth-child(4) {
    position: absolute;
    width: 210px;
    height: 217px;
    left: 50%;
    top: 50%;
    transform: translate(+40%, -50%);
    z-index: 2;
}

.banner-photo img:nth-child(1) {
    position: absolute;
    width: 180px;
    height: 183px;
    left: 75px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.banner-photo img:nth-child(5) {
    position: absolute;
    width: 180px;
    height: 181px;
    right: 75px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.banner-details {
    margin-bottom: 148px;
    text-align: center;
}
.banner-details button{
    background: #F0D64B;
    border-radius: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    padding: 15px 51px 23px ;
    color: #000000;
    border-style: none;

}

.about-us {
    position: relative;
    padding-top: 120px;
}
.about-us-wrapper {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    top: 0;
}
.about-us-wrapper h2{
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    padding: 0 10px;
}
.about-us-wrapper div{
    max-width: 746px;
    margin: 0 10px;
    padding: 31px 72px;
    box-sizing: border-box;
    background: #F0D64B;
    border-radius: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000000;
}
.about-us-content {
    background: #E5E5E5;
    display: flex;
    justify-content: center;
}
.about-us-content p{
    width: 90%;
    font-family: Alice;
    max-width: 776px;
    margin: 49px 0 44px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: justify;
    letter-spacing: 0.05em;

    color: #000000;
}
.about-us-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 18px 0 36px;
}

.about-us-footer img {
    width: 200px;
    height: 150px;
    margin-right: 20px;
}
.about-us-footer button {
    margin-left: 115px;
    width: fit-content;
    height: fit-content;
    background: #F0D64B;
    border-radius: 10px;
    padding: 16px 42px;
    box-sizing: border-box;
    border-style: none;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;
}

.advantages {
    padding-bottom: 60px;
}
.advantages-title {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    margin: 0;
}
.advantages-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.advantages-item {
    width: 50%;
    margin-top: 60px;
    position: relative;
}
.advantages-item p{
    margin: 0;
    top: 20px;
    right: 50px;
    position: absolute;
    z-index: 2;
    text-transform: uppercase;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    background: rgba(240, 214, 75, 0.6);
    width: 240px;
    padding: 20px 0;
}
.advantages-image {
    width: 50%;
    position: relative;
}
.advantages-image-layout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 240px;
    background: rgba(240, 214, 75, 0.4);

}
.advantages-item img{
    width: 100%;
    height: 100%;
}

.products {}
.products-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 67px;
}
.products-title h2{
    padding-left: 10px;
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
}
.products-title a{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    text-decoration: none;
    padding: 10px 0;
    border-bottom: 2px solid transparent;
    transition: 1s;
}
.products-title a:hover {
    border-bottom: 2px solid #f0d64b;
}
.products-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0  30px;
}
.products-item {
    position: relative;
    min-height: 220px;
    background: rgba(240, 214, 75, 0.6);
    margin: 70px 10px 0;
    width: 25%;
    max-width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 1s;
    /*box-sizing: border-box;*/

}
.products-item:hover {
    background: #F0D64B;
}
.products-item>img{
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.products-item p{
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;
}

.products-item__custom {
    background: none;

}
.products-item__custom:hover {
    background: none;
}
.products-item__custom-wrapper img:nth-child(1){
    position: absolute;
    width: 150px;
    height: 148px;
    transform: rotate(-1.29deg);
    bottom: -25px;
    right: 0;
    z-index: 1;
}
.products-item__custom-wrapper img:nth-child(2){
    position: absolute;
    width: 150px;
    height: 207px;
    bottom: -10px;
    left: 0;
}
.bottom-content {
    position: relative;
    padding-bottom: 1px;
}
.footer-gradient {
    background: linear-gradient(179.97deg, rgba(240, 214, 75, 0.54) -0.03%, rgba(255, 255, 255, 0) 25.73%);
    transform: rotate(-179.97deg);
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
}
.contacts {
    padding-top: 78px;
    font-family: Alice;
}
.contacts-title {
    padding-left: 10px;
    margin: 0 0 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000000;

}
.contacts-wrapper {
    padding: 1px 0;
    position: relative;
    margin-bottom: 87px;


}
.contacts-backConnect {
    max-width: 720px;
    background: #E1E1E1;
    padding: 1px 0;
    position: relative;
    margin-bottom: 170px;
}
.border-wrapper {
    max-width: 505px;
    height: 160px;
    border: 1px solid #000000;
    /*box-sizing: border-box;*/
    margin: 82px 0 96px 71px;
}
.contacts-usersDto {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 260px;
    top: 60px;
    left: 130px;
    height: 200px;
}
.contacts-usersDto input {
    background: #FFFFFF;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #8B8B8B;
    border: none;
    padding: 10px 13px 13px 30px;
    box-sizing: border-box;
}
.contacts-info {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    display: flex;
    flex-direction: column;
    z-index: 2;

}
.contacts-info label {
    font-family: Alice;
    background: #F0D64B;
    border-radius: 30px;
    border-style: none;
    width: fit-content;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    padding: 14px 51px;
}
.contacts-info-wrapper {
    background: #F0D64B;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    margin-top: 54px;
    padding: 40px 0 47px;

}
.contacts-info-wrapper p {
    padding-left: 104px;
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    width: fit-content;
    margin: 0;
}
.contacts-info-wrapper p:before {
    content: "";
    position: absolute;
    left: 40px;
    width: 24px;
    height: 24px;
    top: 0;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
}
.contacts-info-wrapper p:nth-child(1):before {
    background: url("../../assets/images/Vector.png") center;
}
.contacts-info-wrapper p:nth-child(2):before {
    background: url("../../assets/images/iphone.png") center;
}
.contacts-info-wrapper p:nth-child(3):before {
    background: url("../../assets/images/mail.png") center;
}
.contacts-info-wrapper p:nth-child(2) {
    margin: 48px 0 32px;
}
.contacts-images img:nth-child(1){
    position: absolute;
    z-index: 1;
    bottom: -49px;
    width: 250px;
    height: 257px;
    left: 178px;
}
.contacts-images img:nth-child(2){
    width: 200px;
    height: 208px;
    position: absolute;
    left: 344px;
    bottom: -51px;
}

.footer-main {
    background: rgb(240,214,75);
    background: linear-gradient(2deg, rgba(240,214,75,1) 1%, rgba(255,255,255,0) 62%);
    padding: 40px 0 1px;
}
.footer {
    display: flex;
    max-width: 1195px;
    margin: 40px auto 37px;
    padding: 0 10px ;
    align-items: center;
    justify-content: center;
}

.grid-background {
    position: absolute;
    /*left: -40px;*/
    width: 109%;
    z-index: -1;
    /*height: 100%;*/
    display: flex;
    flex-wrap: wrap;
}
.grid-background div {
    width: 16%;
    padding-top: 16%;
    z-index: -1;
    box-sizing: border-box;
    /*background: red;*/
    border-bottom: 2px solid #ffeb3b5c;
    border-right: 2px solid #ffeb3b5c;
}

.left-navbar {
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    background: linear-gradient(213.11deg,#f0d64b,hsla(0,0%,100%,0) 45.33%);
    height: 100%;
}

.smol-text {
    font-size: 14px;
}
.left-navbar img{
    width: 40px;
    height: 40px;
    margin-right: 15px;
}
.left-navbar div {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 2px solid #F0D64B;

}
.left-navbar a {
    text-decoration: none;
    text-transform: uppercase;
    color: #000000;
    padding: 10px 0;
}

.drawer {
    display: none;

}

@media screen and (max-width: 1300px) {
    .header-log img {
        left: 4%;
    }
    .fixed-logo  {
        left: calc(4% - 30px);
    }
}

@media screen and (max-width: 1250px) {
    .naw {
        margin-left: 60px;
        min-width: 620px;

    }
}

@media screen and (max-width: 1150px) {
    .header-log img {
        left: 5px;
    }
    .fixed-logo  {
        left: calc(5px - 30px);
    }
}

@media screen and (max-width: 1050px) {
    .grid-background div {
        width: 33%;
        padding-top: 33%;
    }
    .header-log img {
        left: 5px;
    }
    .fixed-logo  {
        left: calc(5px - 30px);
    }
    .drawer {
        display: block;
    }
    .naw {
        display: none;
    }
    .header-log img {
        /*display: none;*/
        position: initial;
        margin-right: 20px;
    }
    .header-log {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .fixed-logo  {
        display: none;
    }
    .header-info {
        text-align: center;
        margin-right: 30px;
    }
    .products-item {
        width: 30%;
        max-width: inherit;
        min-height: 240px;
        flex-grow: 1;

    }
    .products-item__custom-wrapper img:nth-child(2) {
        left: auto;
        right: 106px;
    }
    .advantages-image {
        min-width: 240px;
    }
}

@media screen and (max-width: 950px) {
    .contacts-info {
        width: 45%;
        min-width: 340px;
    }
    .contacts-usersDto {
        left: 50px;
    }
    .contacts-images img:nth-child(1) {
        left: 0;

    }
    .contacts-images img:nth-child(2) {
        left: 130px;
        bottom: -57px;
    }
    .banner-photo img:nth-child(5) {
        right: 5px;
    }
    .banner-photo img:nth-child(1) {
        left: 13px;
    }
    .about-us-wrapper {
        display: flex;
        flex-direction: column;
    }
    .about-us-wrapper h2 {
        margin-bottom: 20px;
        padding: 0;
    }
    .about-us-wrapper div {
        margin: 0;
    }
    .about-us {
        padding-top: 160px;
    }

    }

@media screen and (max-width: 820px) {
    .banner-title-wrapper h1 {
        font-size: 80px;
    }
    .banner-photo img:nth-child(5) {
        right: 0;
    }
    .banner-photo img:nth-child(1) {
        left: 0;
    }
    .banner-photo img:nth-child(2) {
        transform: translate(-125%, -50%);
    }
    .banner-photo img:nth-child(4) {
        transform: translate(+25%, -50%);
    }

    }

@media screen and (max-width: 760px) {
    .banner-title-wrapper {
        min-width: fit-content;
    }
    .banner-title {
        width: 100%;
    }
    .banner-title-wrapper h1 {
        min-width: inherit;
        /*width: 100%;*/
    }
    .banner-title-wrapper p {
        width: fit-content;
    }
    .about-us {
        padding-top: 192px;
    }
    .about-us-footer {
        justify-content: space-between;
    }
    .advantages-item {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .products-item {
        min-width: 200px;
    }
    .advantages-image-layout {
        max-height: 340px;
    }
    .contacts-images img:nth-child(1) {
        /*display: none;*/
        z-index: 11;
        bottom: auto;
        top: 20px;
        left: 20px;
        width: 150px;
        height: 157px;
    }
    .contacts-images img:nth-child(2) {
        display: none;
    }
    .contacts-info {
        width: 100%;
    }
    .contacts-backConnect {
        margin-bottom: 375px;
    }
    .contacts-usersDto {
        left: auto;
        right: 20px;
    }
    .contacts-info label {
        margin-left: 20px;
    }
    .border-wrapper {
        margin: 90px auto;
        width: 80%;
    }
}
@media screen and (max-width: 700px) {
    .banner-photo img:nth-child(5) {
        display: none;
    }
    .banner-photo img:nth-child(1) {
        display: none;
    }
    .banner-photo img:nth-child(4) {
        transform: translate(+40%, -50%);
    }
    .banner-photo img:nth-child(2) {
        transform: translate(-145%, -50%);
    }
    .about-us-wrapper div {
        padding: 31px;
    }
    .banner-title img {
        width: 90px;
        height: 90px;
    }
    .banner-title {
        justify-content: center;
    }
    .banner-title-wrapper h1 {
        font-size: 66px;
    }
    .banner-title-wrapper p {
        font-size: 20px;
        min-width: 253px;
    }
    .banner-title-wrapper {
        position: relative;
    }
    .banner-title-wrapper {
        min-width: 370px;
    }
    .header-wrapper {
        margin-bottom: 60px;
    }
}
@media screen and (max-width: 615px) {
    .banner-photo img:nth-child(3) {
    width: 260px;
        height: 268px;
    }
    .banner-photo img:nth-child(2) {
        width: 180px;
        height: 218px;
    }
    .banner-photo img:nth-child(4) {
        width: 189px;
        height: 197px;
    }
    .about-us-wrapper {
        position: inherit;
    }
    .contacts-backConnect {
        margin-bottom: 400px
    }
    .about-us {
        padding-top: 10px;
    }
    .about-us-wrapper div {
        border-radius: 0;
    }
    .about-us-footer button {
        margin-left: 10px;
    }
}
@media screen and (max-width: 530px) {
    .banner-photo img:nth-child(3) {
        width: 230px;
        height: 230px;
    }
    .banner-photo img:nth-child(4) {
        width: 169px;
        height: 177px;
    }
    .banner-photo img:nth-child(2) {
        width: 160px;
        height: 193px;
    }
    .main-container {
        padding: 0 10px;
    }
    .grid-background div {
        width: 50%;
        padding-top: 50%;
    }
}

@media screen and (max-width: 500px) {
    .banner-title-wrapper {
        min-width: 240px;

    }
    .header-info-wrapper {
        /*background: #f8df5e;*/
        bottom: -66px;
    }
    .phone-type {
        display: none;
    }
    .banner-photo img:nth-child(2) {
        transform: translate(-119%, -50%);
    }
    .banner-photo img:nth-child(4) {
        transform: translate(+11%, -50%);
    }
    .banner-title-wrapper p {
        font-size: 17px;
    }
    .banner-photo {
        height: 271px;
    }
    .about-us-footer img {
        width: 130px;
        height: 100px;
    }
    .about-us-footer button {
        padding: 16px 20px;
    }
    .products-item {
        min-height: 300px;
    }
    .contacts-images img:nth-child(1) {
        display: none;
    }
    .contacts-info label {
        margin: 0 auto;
    }
    .products-item {
        min-width: 215px;
    }
    .contacts-usersDto {
        height: 212px;
        right: auto;
        left: 50%;

        transform: translateX(-50%);
    }
    .header-wrapper {
        padding: 0 15px;
        margin-bottom: 34px;
        margin-top: 20px;
    }
    .banner-title-wrapper h1 {
        font-size: 44px;
    }
    .advantages-item p {
        right: 10px;
    }
}
@media screen and (max-width: 400px) {
    .banner-photo img:nth-child(2) {
        transform: translate(-110%, -50%);
    }
    .banner-photo img:nth-child(4) {
        transform: translate(+4%, -50%);
    }
    .banner-photo img:nth-child(3) {
        width: 202px;
        height: 216px;
    }
    .landing .header-log img {
        display: none;
    }
}
/*@media screen and (max-width: 950px) {}*/

.Uploader{
    width: 200px;
}

.Uploader .fileContainer {
    padding: 0 0 40px;
    box-shadow: none;
}

.Uploader .chooseFileButton {
    margin: 0;
    border-radius: .25rem;
}

.Uploader .fileContainer .chooseFileButton {
  font-size: 14px !important;
}

.Uploader .fileContainer {
  padding: 10px;
}


.admin-naw {
    display: flex;
    justify-content: space-around;
}
.delete-glue-modal {
    background: white;
    padding: 30px;
}
.delete-glue-modal div {
    font-size: 20px;
    display: flex;
    justify-content: space-around;
}
.admin-naw p {
    /*flex-grow: 1;*/
    cursor: pointer;
    text-align: center;
    width: 30%;
    font-size: 22px;
    padding: 16px 10px;
}
.active-tab {
    background: #8b8b8b;
}



.create-glue {
    padding-top: 20px;
}
.appointment {
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.text-field {
    width: 45%;
    min-height: 400px;
}
.characters {
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.texts-field-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.create-glue-wrapper {
    width: 80%;
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.button-submit {
    width: 100%;
    margin: 20px auto;
}
.create-glue-image {
    width: 50%;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.create-glue-image img {
    width: 100%;
}

.create-glue-main-info {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0 10px;
    box-sizing: border-box;
}
.create-glue-main-info div {
    margin: 15px 0;
}

.glues-search {}
.glues-search-topContent {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.glues-search-topContent input{
    border-style: none;
    border-bottom: 2px solid #F0D64B;
    background: none;
    margin-left: 56px;
    outline: none;
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

}
.glues-search-topContent:before {
    content: "";
    position: absolute;
    left: 24px;
    width: 24px;
    height: 24px;
    top: 50%;
    transform: translateY(-50%);
    background: url("../../assets/images/zoom-out.png") center;
}
.glues-search-topContent h2{
    margin: 0 26px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
}
.glues-search-items {
    padding-top: 56px;
    display: flex;
    flex-direction: column;
}
.glues-count {
    background: #E5E5E5;
    border-radius: 50%;
    text-align: center;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 20px;
    color: #000000;
}
.glues-list {
    padding: 0 24px;
}
.glues-category {
    cursor: pointer;
    text-transform: uppercase;
    max-width: 580px;
    background: #F0D64B;
    border-radius: 10px;
    padding: 15px 0 15px 58px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin: 20px 0;
    position: relative;
    transition: 0.5s;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.glues-category:before {
    content: "";
    position: absolute;
    left: 20px;
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: url("../../assets/images/back-arrow.png");
}
.glues-category:hover {
    background: #fcde40;
}

.glues-subcategory {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    text-transform: uppercase;
    margin-left: 30px;
    /*padding-bottom: 3px;*/
    border-bottom: 2px solid transparent;
    position: relative;
    width: fit-content;
    transition: 1s;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.glues-subcategory:hover {
    border-bottom: 2px solid #F0D64B;
    /*padding-bottom: 1px;*/
}
.glues-subcategory:before {
    background: url("../../assets/images/arrow-point-to-right.png");
    content: "";
    position: absolute;
    left: -30px;
    width: 15px;
    height: 12px;
    top: 50%;
    transform: translateY(-50%);
}
.glues-item-wrapper {
    display: flex;
    flex-direction: column;
}
.glues-item {
    text-decoration: none;
    padding: 10px 5px;
    font-style: normal;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    width: fit-content;
    margin: 10px 70px 0;
    border-bottom: 2px solid transparent;
    transition: 1s;
}
.glues-item:hover {
    border-bottom: 2px solid #F0D64B;
}

@media screen and (max-width: 730px) {
    .glues-search-topContent {
        flex-direction: column-reverse;
    }
    .glues-search-topContent input {
        width: fit-content;
        margin-top: 35px;
    }
    .glues-search-topContent:before {
        bottom: 0;
        top: auto;
        transform: none;
    }
    .glues-search-items {
        padding-top: 10px;
    }
    .glues-count {
        flex: none;
    }
}
@media screen and (max-width: 620px) {
    .glues-list {
        padding: 0;
    }
    .glues-search-topContent h2 {
        margin: 0;
    }
    .glues-search-topContent:before {
        left: 0;
    }
    .glues-search-topContent input {
        margin-left: 30px;
    }
    }
@media screen and (max-width: 450px) {
    .glues-subcategory {
        width: 90%;
        box-sizing: border-box;
    }
}


.glue-card {
    display: flex;
}

.glue-card-image {
    width: 50%;
    flex-grow: 1;
    max-width: 700px;
}

.glue-card-image img{
    width: 100%;
}

.glue-card-info {
    width: 50%;
    display: flex;
    padding-left: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.glue-card-info-button {
    border-style: none;
    background: #F0D64B;
    border-radius: 20px;
    width: fit-content;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-top: 40px;
    padding: 20px 100px;
    margin-left: auto;

}
.glue-card-title {
    margin-bottom: 20px;
}
.glue-card-title h2{
    text-transform: uppercase;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 75px;
    color: #000000;
    margin: 0;
}
.glue-card-title h3{
    margin: 0;
    text-transform: uppercase;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
}

.glue-card-info-characters {
    background: #E5E5E5;
    width: 100%;
    box-sizing: border-box;
    /*min-height: 480px;*/
    /*margin-top: 30px;*/
    padding: 0 40px 0 50px;
    font-family: Alice;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    border-radius: 8px;
    min-height: 363px;
    /*overflow-y: hidden;*/
    /*letter-spacing: 0.1em;*/

    color: #000000;
}
.glue-card-info-characters h3 {
    font-family: Alice;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 27px;
    color: #000000;
    margin: 50px 0 40px;

}

.glue-card-info-characters div {
    margin-bottom: 50px;
    overflow-y: scroll;
    max-height: 220px;
    letter-spacing: 0.08em;

}
.glue-card-bottom-wrapper {
    display: flex;
    margin-top: 40px;

}
.glue-card-appointment {
    width: 50%;
    background: #E5E5E5;
    padding: 0 40px 0 50px;
    box-sizing: border-box;
    margin-right: 20px;
    max-height: 500px;
    border-radius: 8px;
}
.glue-card-appointment div {
    font-family: Alice;
    letter-spacing: 0.08em;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    height: 261px;
    line-height: 21px;
    overflow-y: scroll;
    margin-bottom: 50px;
}

.glue-card-appointment h3 {
    font-family: Alice;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 27px;
    color: #000000;
    margin: 50px 0 40px;
}
.glue-card-technical-characters {
    width: 50%;
}

.glue-card-technical-characters h2 {
    font-family: Alice;
    background: #F8E479;
    /*border-radius: 8px;*/
    padding: 21px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #000000;
    margin: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.glue-card-technical-characters-table {
    width: 100%;
    border-collapse: collapse;
}
.glue-card-technical-characters-table tr {
    font-size: 18px;
}
.glue-card-technical-characters-table td {
    padding: 10px 5px 5px 15px;
    background: #ffffff;
    /*border: 1px solid #F8E479;*/
}
.glue-card-technical-characters-table tr:nth-child(even) td{
    font-size: 18px;
    background-color: #f8e47987;
}

.glue-card-technical-characters-table tr:last-child td{
    font-size: 18px;
    background-color: #f8e47987;

}

.close-contact-us-modal {
    position: absolute;
    right: 20px;
    top: 20px;
    font-weight: 700;
    border-style: none;
    padding: 5px 10px;
    border-radius: 50%;
    background: #E1E1E1;
    font-size: 15px;

}


.scroll::-webkit-scrollbar {
    width: 12px;
}

.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #f8e479;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
.glue-card-contact-us-modal {
    background: linear-gradient(167deg, rgba(240,214,75,1) 0%, rgba(255,255,255,1) 76%);
    padding: 60px;
    position: relative;
    box-sizing: border-box;
}

@media screen and (max-width: 1000px) {
    .glue-card {
        flex-direction: column;
    }
    .glue-card-info {
        width: 100%;
        padding-left: 0;
        flex-direction: column-reverse;
    }
    .glue-card-image {
        width: 100%;
        max-width: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .glue-card-image img {
        max-width: 500px;
    }
    .glue-card-bottom-wrapper {
        flex-direction: column-reverse;
    }
    .glue-card-appointment {
        width: 100%;
        margin-right: 0;
    }
    .glue-card-info-button {
        margin-bottom: 40px;
    }
    .glue-card-technical-characters {
        width: 100%;
        margin-bottom: 40px;
    }
    .glue-card-appointment div {
        height: 330px;
    }
    .glue-card-info-characters div {
        max-height: 330px;
    }
}
@media screen and (max-width: 850px) {
    .glue-card-contact-us-modal {
        padding: 60px  30px 30px;
    }
}

@media screen and (max-width: 750px) {
    .glue-card-contact-us-modal {
        padding: 60px  30px 1px;
    }
}
@media screen and (max-width: 500px) {
    .glue-card-contact-us-modal .contacts-wrapper {
        margin-bottom: 30px;
    }
    .glue-card-contact-us-modal .contacts-backConnect {
        margin-bottom: 79px;
    }
    .glue-card-contact-us-modal .contacts-info-wrapper {
        display: none;
    }
    .glue-card-contact-us-modal {
        padding: 60px  15px 1px;
    }
}
@media screen and (max-width: 526px) {
    .glue-card-info-button {
        margin: 40px auto;
    }
    .glue-card-info-characters {
        padding: 0 20px 0 25px;
    }
    .glue-card-appointment {
        padding: 0 20px 0 25px;
    }
}

@media screen and (max-width: 600px) {
    .glue-card-info-characters div {
        overflow: auto;
        max-height: none;
    }
    .glue-card-appointment div {
        overflow: auto;
        max-height: none;
    }
    .glue-card-title h2 {
        font-size: 37px;
        line-height: 45px;
    }
    .glue-card-title h3 {
        font-size: 20px;
    }


    }
/*@media screen and (max-width: 760px) {}*/
